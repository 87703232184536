import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import styled from 'styled-components'
import InvestorReports from '../components/featured/InvestorReports'
import BreadHome from '../components/BreadHome'

const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const Investor = (props) => {


  const dataArr = [];
  const xArr = [];

  const renderInvestor = (edge, index) => {

    dataArr[index] = { x: edge.node.name, y: edge.node.count }
    xArr.push(edge.node.name)

  }

  const renderPlayer = (edge, index) => {

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
          <BS.Row>
            <BS.Col sm={6}>
              <div>
                < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
              </div>
            </BS.Col>
            <BS.Col sm={6}><RightCol>
              investments : {edge.node.countUK}
            </RightCol>
            </BS.Col>
          </BS.Row>
        </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Investors Ranked By UnicornAlert</title>
        <meta name="title" content="Investors Ranked By UnicornAlert"></meta>
        <meta name="description" content='Get the latest updates from startups, investors and fundraising in your realtime UnicornAlert dashboard.' /><meta property="og:description" content='Get the latest updates from startups, investors and fundraising in your realtime UnicornAlert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1240 }}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item> <Link to={`/`}>Data</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Investors</BS.Breadcrumb.Item>
      </BreadcrumbLite>

        <BS.Row>
          <BS.Col sm={8}>
            <BS.Card><BS.Card.Header><h1 style={{ marginTop: 10, fontSize: 35 }}>{props.data.allInvestor.totalCount} investors</h1></BS.Card.Header></BS.Card>

            {props.data.allInvestor.edges.map(renderPlayer)}

          </BS.Col>
          <BS.Col sm={4}>

            {props.data.allInvestor.edges.map(renderInvestor)}

            {console.log(dataArr.reverse())}
            <InvestorReports />



          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allInvestor(filter: {countUK: {gt: 0}}, sort: {order: DESC, fields: countUK}, limit: 400) {
    edges {
      node {
        id
        name
        location
        type
        count
        countUK
      }
    }
    totalCount
  }
}
`

export default Investor